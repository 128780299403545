@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

.login-container {
  display: flex;
  align-items: center;
  width: 1000px;
  min-height:80vh;
  margin: auto;
  margin-top:30px;
  box-shadow: 1px 1px 3px 0px gray;
}

.image-column {
  width: 60%;
  height: 100%;
}

.image-column img {
  width: 100%;
  height:100%;
  object-fit: cover;
}

.form-column {
   width: 60%;
  padding: 20px;
  border-left: 1px solid #ccc;
}

.form-column h2 {
  margin-bottom: 20px;
  font-family: 'Lato', monospace;
}
.form-column p{
  font-family: "Lato","sans-serif";
  font-size: 16px;
  font-weight: 600;
}

.form-column label {
  display: block;
  margin-bottom: 10px;
  font-family: 'Lato';
}

.form-column input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  
}

.form-column button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 700;
}

.form-column button:hover {
  background-color: #0056b3;
}

.signup{
  font-family: "Lato","sans-serif";
  font-size: 16px;
  font-weight:400;
  margin-top:10px;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .login-container{
    flex-direction: column;
    width: 80%;
    height: 700px;
  }
  .image-column{
    width: 100%;
    height:35%;
  }
  .form-column{
    width: 100%;
  }
}