@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@300..900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* hero */
.hero {
  width: 100%;
  height: 100vh;
  display: flex;
}

.hero .hero-image {
  width: 55%;
  height: 100%;
  position: relative;
  cursor: pointer;
}
.hero .hero-image .image-content {
  width: 70%;
  height: 45%;
  position: absolute;
  bottom: 20%;
  left: 15%;
  background-color: #fffcfc;
  opacity: 0.85;
  display: flex;
}

.image-content-inner {
  border: 3px solid rgba(180, 36, 0, 0.714);
  margin: 10px;
}
.image-content .heading {
  height: 40%;
  font-size: 1.9rem;
  font-weight: 550;
  text-transform: capitalize;
  margin: 10px;
  font-family: "Frank Ruhl Libre", sans-serif;
  text-align: center;
}

.image-content .image-content-desc {
  height: 60%;
  font-size: 1.1rem;
  font-weight: 400;
  font-family: "Merriweather";
  text-align: center;
  margin: 30px 20px;
}

.hero-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-recipes {
  display: flex;
  background-image: linear-gradient(to bottom right, #ffffff, #f5fcff);
  width: 45%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  overflow-y: auto;
}

.hero-recipes::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hero-recipes {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.hero-recipes .cards {
  width: 230px;
  height: 300px;
  border: 2px solid gray;
  margin: 20px;
}

.hero-recipes .group-tag {
  width: 55%;
  height: 25%;
  margin: 10px;
  text-align: center;
  text-transform: capitalize;
  text-decoration: underline;
  text-underline-offset: 4px;
  font-size: 2rem;
}

.group-tag img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* tag-recipes */

.specific-recipes-container {
  width: 100%;
  margin: 0px auto;
  background: #fff9f8;
}
.specific-recipes {
  display: flex;
  width: 100%;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.specific-title h2 {
  font-size: 32px;
  text-transform: capitalize;
  font-family: "Frank Ruhl Libre", "Arial Narrow", Arial, sans-serif;
  padding: 20px 15px 1px;
  display: inline-block;
  letter-spacing: 1px;
  font-weight: 600;
  margin-bottom: 10px;
  border-bottom: 3px solid rgb(65, 0, 0);
}

.trending{
  font-size: 32px;
  text-transform: capitalize;
  font-family: "Frank Ruhl Libre", "Arial Narrow", Arial, sans-serif;
  margin-left:50px;
  min-width: 30px;
  margin-top: 30px;
  display: inline-block;
  letter-spacing: 1px;
  font-weight: 600;
  margin-bottom: 10px;
}

.specific-title:hover {
  text-decoration: underline;
}
.specific-recipe-box {
  display: flex;
  width: 100%;
  overflow: auto;
  margin-bottom: 20px;
}
.specific-recipe-box::-webkit-scrollbar {
  display: none;
}

.categories-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to bottom right, #ffffff, #ffefe8);
}

#typewriter-text {
  font-size: 44px;
  width: 100%;
  font-family: "Frank Ruhl Libre";
  height: 200px;
  text-align: center;
  color: #333; /* Adjust color as needed */
  padding-top: 80px;
  border-radius: 5px;
}
.categories-container-inner {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #333;
}
.category {
  text-align: center;
  margin: 20px;
}
.cat-link {
  text-decoration: none;
  font-size: 18px;
  color: rgba(52, 2, 0, 0.933);
  font-weight: 500;
}

.category img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.category img:hover {
  transform: scale(1.1);
}

.category-name {
  display: block;
  margin-top: 10px;
}

/* Buttons */

ul {
  list-style: none;
}

/* Mid Area */

.mid-area {
  display: flex;
  margin: 0 auto;
  padding: 0 10px;
  margin-bottom: 40px;
}

.widecards {
  margin: 12px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 70%;
  overflow: auto;
}

/* Sidebar */

.sidebar {
  position: sticky;
  top: 20px;
  align-self: start;
}

/* Popular */

.popular {
  margin-top: 10px;
  margin-bottom: 35px;
}

.popular h1 {
  font-size: 35px;
  margin-bottom: 30px;
  backface-visibility: hidden;
}

.popular li:not(:last-child) {
  margin-bottom: 20px;
}

.popular article {
  height: 85px;
  display: flex;
}

.popular img {
  width: 85px;
  border-radius: 3px;
  transition: all 0.3s;
}
.popular-link {
  text-decoration: none;
  color: rgb(54, 0, 0);
}
.popular li:hover img {
  transform: scale(1.1);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.25);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 2);
}

.popular .description {
  padding: 0px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.popular .tag-2 {
  font-size: 12px;
}

.popular h2 {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.3;
  margin: 3px 0;
  text-transform: capitalize;
  transition: all 0.2s;
}

.popular .stars {
  font-size: 14px;
}

.fa-star {
  color: gold;
}

.recipes {
  margin-top: 10px;
  margin-bottom: 20px;
}

.recipes h1 {
  margin-bottom: 35px;
}

.recipes .cards {
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: 125px;
  grid-gap: 20px;
}

.small {
  grid-row: span 2;
}

.medium {
  grid-row: span 3;
}

.big {
  grid-row: span 4;
}

.recipes .stars {
  color: white;
  position: relative;
}

.recipes .fa-star {
  font-size: 19px;
  margin-left: 2px;
}

.recipes .stars,
.recipes h2 {
  opacity: 0;
  transform: translateY(10px);
  transition: all 0.4s;
}

.recipes .card:hover .stars,
.recipes .card:hover h2 {
  opacity: 1;
  transform: translateY(0);
}

.recipes .special-link {
  display: block;
  margin: 0 auto;
  width: max-content;
}

@media screen and (max-width: 768px) {
  .hero {
    flex-direction: column;
  }
  .categories-container-inner {
    overflow: auto;
  }
  .hero .hero-image {
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
  }
  .hero-recipes {
    width: 100%;
  }
  .image-content .heading {
    height: 40%;
    font-size: 1.1rem;
  }
  .image-content .image-content-desc {
    height: 60%;
    font-size: 8px;
    font-weight: 400;
    font-family: "Merriweather";
    text-align: center;
    margin: 30px 20px;
  }
  .mid-area {
    flex-direction: column;
  }
  .mid-area .widecards {
    margin: auto;
  }
}
