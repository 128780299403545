/* Navbar.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap');

.navbar {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 80px;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
  height: 100%;
}

.logo-link {
  text-decoration: none;
  color: inherit;
}

.logo {
  display: flex;
  align-items: center;
}

.logo-image {
  width: 40px; /* Adjust the width as needed */
  height: 40px; /* Adjust the height as needed */
  margin-right: 10px; /* Add some space between the image and text */
}

.logo-text {
  font-size: 1.5rem; 
  font-weight: bold; 
  font-family:"Roboto flex";
  color: #350800c7; 
}


.nav-menu {
  display: flex;
  margin-top: 20px;
}
.nav-menu li {
  text-align: center;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  padding: 15px;
}
.hamburger {
  display: none;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #101010;
}

.dropdown {
  position: relative;
}

.dropdown-btn {
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  text-transform: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

@media only screen and (max-width: 768px) {
  .nav-menu {
    position: fixed;
    left: -100%;
    top:60px;
    flex-direction: column;
    background-color: #fff;
    width: 100%;
    text-align: center;
    transition: 0.3s;
    box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
    z-index: 1;
  }

  .nav-menu.active {
    left: 0;
  }

  .nav-menu li {
    margin: 2.5rem 0;
  }

  .hamburger {
    display: block;
    cursor: pointer;
  }

  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
}


.navbar-user-pic {
  width: 30px;
  height:30px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
}

.navbar-user-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
