@import url('https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@300..900&display=swap');

.blog-card {
    width: 260px;
    margin: 20px;
    height: 400px;
    border: 1px solid #e0e0e0;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .blog-card:hover {
    transform: translateY(-5px);
  }
  
  .blog-card .blog-card-image {
    margin: auto;
    width: 100%;
    height: 60%;
  }
  .blog-card .blog-card-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* blog details --> tag and title */
  
  .blog-card .blog-details {
    padding: 5px 0;
    margin: 5px 10px;
    font-family: "Frank Ruhl Libre",sans-serif;
  }
  
  .blog-card .blog-details .blog-title-tag{
    min-height:90px ;
  }
  
  .blog-title-tag p{
   margin: 0;
   padding: 0;
   overflow: hidden;
   white-space: nowrap;
   text-overflow: ellipsis;
  }
  
  .blog-title-tag .blog-tag{
    padding: 5px;
    font-weight:700;
    color: #ab0000;
    text-transform: uppercase;
  }
  
  
  .blog-title-tag .blog-title {
    font-size:25px;
    color: #333;
    padding: 5px;
    height: 50px;
    font-weight:400;
    text-transform: capitalize;
  }
  
  
  
  /* blog-details blog-actions  */
  .blog-actions {
    display: flex;
    height: 50px;
    align-items: center;
  }
  
  .blog-actions .action {
    font-weight:300;
    width: 20%;
    height: 40px;
    font-size: 1.1rem;
    text-align: center;
  }
  
  .blog-actions .action span {
    margin-left: 5px;
    color: #232323;
  }
  

  .blog-actions .faHeart {
    color: #850000;
  }
  .blog-actions .faEye{
    color: #232323;
  }
  

  .blog-actions .action:hover {
    cursor: pointer;
    color: #d30000; 
  }

  .blog-link{
    text-decoration: none;
  }
  