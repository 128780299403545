@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:wght@700&display=swap');

.all-blogs {
  display: flex;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
  justify-content: center;
  min-height: 100vh;
}


.loading-text {
  text-align: center;
  font-size: 18px;
  color: #666;
  margin-top: 50px;
}

.blog-card-skeleton{
  margin:20px;
  background-color:none;
}



