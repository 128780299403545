.outer-blog {
  display: flex;
}

/* profile */
.profile-info {
  min-width: 30%;
  height: 100vh;
  position: sticky;
  margin-left: 10px;
  border: 1px solid rgb(209, 209, 209);
  top: 0px;
  background-color: #ffffff;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


.user-details {
  display: flex;
  flex-direction: column;
}

.user-pic {
  display: flex;
  justify-content: center;
  align-self: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  margin: 10px;
}

.user-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}



.user-info {
  font-family: Arial, sans-serif;
  font-size: 16px;
  color: #333;
  text-align: left;
}

.editable-info {
  display: flex;
  flex-direction: column;
}

.heading{
  font-size: 20px;
  color: #555;
  font-family: "Roboto";
}
.text{
  font-size: 16px;
  color: rgb(68, 0, 0);
  font-family: Arial, Helvetica, sans-serif;
}

.user-about{
  font-size: 16px;
  margin-top: 10px;
  font-style: italic;
}

.logout {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 10px;
  cursor: pointer;
}
.edit-profile {
  background-color: #4e6eff;
  color: white;
  border: none;
  padding: 5px 10px;
  margin: 10px;
  border-radius: 5px;
  cursor: pointer;
}
.logout:hover {
  background-color: #c82333;
}

/* blog cards */
.inner-blog {
  display: flex;
  width: 70%;
  justify-content: space-between;
  flex-direction: column;
  margin: 10px;
}
.user-blogs{
  margin: 10px;
}
.user-blogs-heading{
  text-align: center;
  margin: 20px;
  text-transform: capitalize;
}
.blog-content {
  display: flex;
  margin: 10px;
  width: 90%;
  margin: auto;
  height: 275px;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 2px 3px 2px rgb(204, 204, 204);
}

.blog-image {
  width: 40%;
  height: 100%;
}

.blog-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.blog-content-inner {
  width: 70%;
  margin: 10px;
}

.blog-content-inner h4 {
  font-family: "Roboto";
  font-size: 1.8rem;
  text-transform: capitalize;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.blog-content-inner .blog-link {
  text-transform: uppercase;
  font-weight: bolder;
  color: #cfb000;
}

.blog-content-inner .blog-link:hover {
  text-transform: uppercase;
  font-weight: 1000;
  color: #6e5e00;
}

.blog-content-inner .Date,
.Author {
  font-weight: 600;
  color: #777777;
}

.blog-content-inner p {
  font-family: "sans-serif";
  font-size: 17px;
  color: #373737;
}

.delete-button {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.delete-button button {
  background: none;
  border: none;
  color: #555;
  cursor: pointer;
  margin-right: 10px;
}

.delete-button button:hover {
  color: #ff0000; /* Change color on hover */
}

.message{
  width: 70%;
  font-size:40px;
  font-family: "Roboto";
  color: rgb(96, 0, 0);
  padding: 10px 20px; 
  border-radius: 5px; 
  margin-top:200px;
  text-align: center;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .outer-blog{
    flex-direction: column-reverse;
  }
  
  .profile-info{
    position: static;
    max-width:90%;
    margin: auto;
    height:450px;
    
  }
  .inner-blog{
    box-sizing: border-box;
    width: 90%;
    margin: auto;
    font-size: 14px;
  }
  .inner-blog img{
     width: 100%;
     height: 100%;
     object-fit: cover;
  }
  .blog-content{
    height: 200px;
  }
  .blog-content-inner p,.Date,.Author,.blog-link{
    font-size: 14px;
  }
  .blog-content h4{
    font-size: 1.1rem;
  }
}

